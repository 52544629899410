$(() => {
    'use-strict'
    
    const acceptCookies = $.cookie('acceptCookies') || '';
    const widgetCookies = $('.widget-cookie');

    if (!acceptCookies) {
      $.cookie('acceptCookies', false, { expires: 10*365 });
      widgetCookies.css({visibility: 'visible'});
    }

    if(acceptCookies == 'false') {
      widgetCookies.css({visibility: 'visible'});
    }

    widgetCookies.on('click', '.btn-close', function (e) {
      e.preventDefault();
      $.cookie('acceptCookies', true, { expires: 10*365 });
      widgetCookies.hide();
    });

    widgetCookies.on('click', '.btn-accept', function (e) {
      e.preventDefault();
      $.cookie('acceptCookies', true, { expires: 10*365 });
      widgetCookies.hide();
    });
});