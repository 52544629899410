$(() => {
    'use-strict'

    $(document).ready(() => {

        /**-----------------------------
         *  LGPD
         * ---------------------------*/
        require('./cookies');

        /**-----------------------------
         *  Disable right click and drag on Images
         * ---------------------------*/
        $('body').on('contextmenu', 'img', () => { return false; });
        $('body').on('mousedown', 'img', () => { return false; });

        /**-----------------------------
         *  Create Tooltip
         * ---------------------------*/
        $('[data-toggle="tooltip"]').tooltip();

        /**-----------------------------
         *  Configure Nice Select
         * ---------------------------*/
        if ($('.single-select').length){
            $('.single-select').niceSelect();
        }

        /**-----------------------------
         *  Navbar fix
         * ---------------------------*/
        $(document).on('click', '.navbar-area .navbar-nav li.menu-item-has-children>a', (e) => {
            e.preventDefault();
        });

        /**-----------------------------
         *  Menu
         * ---------------------------*/
        $('.menu').click (() => {
            $(this).toggleClass('open');
        });

        /**-----------------------------
         *  Mobile Menu
         * ---------------------------*/
        if ($(window).width() < 992) {
            $(".in-mobile").clone().appendTo(".sidebar-inner");
            $(".in-mobile ul li.menu-item-has-children").append('<i class="fas fa-chevron-right"></i>');
            $('<i class="fas fa-chevron-right"></i>').insertAfter("");

            $(".menu-item-has-children a").click((e) => {
                e.preventDefault();
                $(this).siblings('.sub-menu').animate({
                    height: "toggle"
                }, 300);
            });
        }

        const menutoggle = $('.menu-toggle');
        const mainmenu = $('.navbar-nav');

        menutoggle.on('click', () => {
          if (menutoggle.hasClass('is-active')) {
            mainmenu.removeClass('menu-open');
          } else {
            mainmenu.addClass('menu-open');
          }
        });

        /**-----------------------------
         *  Parallax
         * ---------------------------*/
        if ($.fn.jarallax) {
            $('.jarallax > .slideshowLayer').jarallax({
                speed: 0.5
            });
        }

        /**-----------------------------
         *  Back to Top
         * ---------------------------*/
        $(document).on('click', '.back-to-top', function () {
            $("html, body").animate({
                scrollTop: 0
            }, 2000);
        });

        /** -----------------------------
         *  Menu Landing Page
         * -----------------------------*/
        $('#realdeal_main_menu ul > li').onePageNav({
            currentClass: false,
            changeHash: false,
            scrollSpeed: 750,
            scrollThreshold: 0.5,
            filter: '',
            easing: 'swing',
            begin: function () {
                //I get fired when the animation is starting
                if ($('.navbar-collapse.show').length > 0) {
                    $('.navbar-collapse.show').toggleClass('show');
                }
            },
            end: function () {
                //I get fired when the animation is ending
                
            },
            scrollChange: function ($currentListItem) {
                //I get fired when you enter a section and I pass the list item of the section
            }
        });

        $('#right-btn, #right-mobile, #internal-btn').onePageNav({
            currentClass: false,
            changeHash: false,
            scrollSpeed: 750,
            scrollThreshold: 0.5,
            filter: '',
            easing: 'swing',
            begin: function () {
                //I get fired when the animation is starting
                if ($('.navbar-collapse.show').length > 0) {
                    $('.navbar-collapse.show').toggleClass('show');
                }
            },
        });

        /**-----------------------------
         *  Preloader
         * ---------------------------*/
        const preLoder = $('#preloader');
        preLoder.fadeOut();

        /** -----------------------------
         *  Open Popup
         * -----------------------------*/
        $.each($("a:not([link-ok='true']):not([data-toggle='collapse']):not([href=':back']):not(.imgFancy.imgThumb):not([no-ajax]):not([target='_blank'])"), function (i, el) {
            if ($(this).attr("target") && $(this).attr("target").toLowerCase() == 'popup') {
                $(this).removeAttr("target");
                $(this).attr("data-href", $(this).attr("href"));
                $(this).removeAttr("href");
                $(this).on('click', function (evt) {
                    evt.preventDefault();
                    const url = $(evt.currentTarget).attr("data-href")
                    let w = window.outerWidth * 0.5, h = window.outerHeight * 0.5, l = (window.outerWidth - w) / 2;
                    window.open(url, "Popup", "width=" + w + ", height=" + h + ", top=32, left=" + l + ", status=yes,toolbar=no,menubar=no,location=no,addressbar=no");
                    return false;
                });
            }
        });

        /**-----------------------------
         *  Whatsapp Links
         * ---------------------------*/
        $.each($("a[data-type='whatsapp']"), function () {
            const phone = $(this).attr("href").replace('tel:+', '');
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                const link = `https://api.whatsapp.com/send?phone=${phone}&text=Olá, gostaria de informações.`;
                $(this).attr("href", link);
            } else {
                const link = `https://web.whatsapp.com/send?phone=${phone}&text=Olá, gostaria de informações.`;
                $(this).attr("href", link);
                $(this).attr("target", "_blank");
            }
        });

        /**-----------------------------
         *  Seja Cliente Auto Select
         * ---------------------------*/
        $('#seja-cliente').on('click', function(el) {
            el.preventDefault();
            $('.navbar-nav #seja-cliente-menu').trigger('click');
        });

        $('#seja-cliente-menu').on('click', function() {
            $('select[name="department"] option').each(function(i, el) {
                if($(el).text() == 'Proposta' || $(el).text() == 'Quero ser cliente') {
                    $('select[name="department"]').val($(el).val());
                }
            });

            $('select[name="department"]').niceSelect({
                method: 'update'
            });
        });

        $('#contato-menu').on('click', function(e) {
            $('select[name="department"]').val('');
            $('select[name="department"]').niceSelect({
                method: 'update'
            });
        });

        /**-----------------------------
         *  Form Phone Mask
         * ---------------------------*/
        var phoneMask = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        phoneOpt = {
            onKeyPress: function(val, e, field, options) {
                field.mask(phoneMask.apply({}, arguments), options);
            }
        };
    
        $('input[name="phone"]').mask(phoneMask, phoneOpt);
    });
    
    $(window).on('scroll', () => {

        /**------------------------------------------
         *  Sticky menu activation && Sticky Icon Bar
         * -----------------------------------------*/
        const mainMenuTop = $('.navbar-area');
        if ($(window).scrollTop() >= 1) {
            mainMenuTop.addClass('navbar-area-fixed');
        }
        else {
            mainMenuTop.removeClass('navbar-area-fixed');
        }

        /**-----------------------------
         *  Back to Top
         * ---------------------------*/
        const backtoTop = $('.back-to-top');
        if ($(window).scrollTop() > 300) {
            backtoTop.fadeIn(300);
        } else {
            backtoTop.fadeOut(300);
        }
    });

    $(window).on('load', () => {

        /**-----------------------------
         *  Back to Top
         * ---------------------------*/
        const backtoTop = $('.back-to-top');
        backtoTop.fadeOut();
    });
});